"use client";

import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import type { Theme } from "../../../types/theme";
import type { Ad } from "../../../types/ads/adResource";

import styles from "./Takeover.module.scss";
import { Icon } from "../../Icon/Icon";
import { Ad as AdComponent } from "../Ad";
import {
  adPlacementMap,
  parseAdPlacement,
} from "../utils/helpers/adPlacementMap";
import { getLayout } from "../utils/helpers";
import { frequencyController } from "../utils/helpers/adFrequencyController";
import { useTcfPermission } from "../../../tcf/helpers";
import { AdInfoWrapper } from "../Adinfo/AdInfoWrapper";

const brandName: Theme =
  process.env.NEXT_PUBLIC_UI_THEME === "ekonomi" ? "ekonomi" : "core";

const Takeover = ({ takeoverAdResource }: { takeoverAdResource: Ad }) => {
  const [isAdAvailable, setIsAdAvailable] = useState(false);
  const [shouldShowTakeover, setShouldShowTakeover] = useState(false);
  const [takeoverTimedOut, setTakeoverTimedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creativeId, setCreativeId] = useState("");
  const [takeoverTimeoutId, setTakeoverTimeoutId] = useState<
    number | NodeJS.Timeout
  >();

  const marketingConsent = useTcfPermission("CMP:marketing");

  const placement = takeoverAdResource.placement.split(".");
  const placementClassName = `${Array.isArray(placement) ? placement[1] : ""}`;

  const closeTakeover = useCallback(() => {
    setShouldShowTakeover(false);
    document.body.classList.remove("no-scroll");
  }, []);

  const onAdAvailable = () => {
    if (!takeoverTimedOut) {
      setIsAdAvailable(true);
      clearTimeout(takeoverTimeoutId);
    }
  };

  useEffect(() => {
    if (!takeoverAdResource) {
      return;
    }

    let withinFrequency = false;
    const { placementContext, placementWebName, correct } = parseAdPlacement(
      takeoverAdResource.placement,
    );

    if (correct && marketingConsent) {
      const layout = getLayout();
      const position =
        adPlacementMap[placementContext][brandName][placementWebName]?.[layout];
      const adRule = takeoverAdResource.placementRules.filter(
        (ad) => ad.rules.position === position,
      );

      if (position && adRule.length > 0 && adRule[0].rules?.fetch_frequency) {
        withinFrequency = frequencyController(
          adRule[0].rules.fetch_frequency,
          position,
          takeoverTimedOut,
        );
      }

      if (withinFrequency) {
        setShouldShowTakeover(true);
      }
    }
  }, [takeoverAdResource, marketingConsent, takeoverTimedOut]);

  useEffect(() => {
    if (!takeoverTimeoutId) {
      const timeoutId = setTimeout(() => {
        setTakeoverTimedOut(true);
        closeTakeover();
      }, 2000);

      setTakeoverTimeoutId(timeoutId);

      return () => clearTimeout(timeoutId);
    }
  }, [closeTakeover, takeoverTimeoutId]);

  useEffect(() => {
    if (shouldShowTakeover && isAdAvailable) {
      document.body.classList.add("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [shouldShowTakeover, isAdAvailable]);

  if (!takeoverAdResource) {
    return null;
  }

  return (
    shouldShowTakeover && (
      <div className={cn([styles.container, !isAdAvailable && styles.hidden])}>
        <div className={styles.header}>
          <div
            className={cn([
              styles.headerLogo,
              brandName === "core" ? styles.coreLogo : styles.economyLogo,
            ])}
          >
            <span className={styles.hiddenText}>
              {brandName === "core" ? "Omni" : "Omni Ekonomi"}
            </span>
          </div>
          <AdInfoWrapper
            isLoading={isLoading}
            isLoggedIn={takeoverAdResource.user.isLoggedIn}
            creativeId={creativeId}
            placementClassName={placementClassName}
          />
          <button className={styles.button} onClick={closeTakeover}>
            Till {brandName === "core" ? "Omni" : "Omni Ekonomi"}
            <Icon variant="back"></Icon>
          </button>
        </div>
        <div className={styles.body}>
          <AdComponent
            onFailure={closeTakeover}
            notAvailable={closeTakeover}
            takeoverAvailable={onAdAvailable}
            loadAdRightAway={shouldShowTakeover}
            initialSize={{ width: "100vw", height: "100vh" }}
            resource={takeoverAdResource}
            setTakeoverIsLoading={setIsLoading}
            setTakeoverCreativeId={setCreativeId}
          />
        </div>
      </div>
    )
  );
};

export { Takeover };
