"use client";

import Link from "next/link";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { usePathname, useSearchParams } from "next/navigation";

import type { ReactNode } from "react";
import type { Article } from "../../types/content";

import { usePulse } from "../../pulse/usePulse";
import styles from "./InfoCard.module.scss";
import { useArticleModalStore } from "../../store/useArticleModalStore";

const InfoCardLink = ({
  article,
  slug,
  externalLink,
  children,
}: {
  article: Article;
  externalLink?: string;
  slug?: string;
  children: ReactNode;
}) => {
  const { trackInfoCardClick } = usePulse();

  const { ref, inView } = useInView({
    threshold: 2 / 3,
  });
  const { trackInfoCardImpression } = usePulse();
  const { openModal } = useArticleModalStore();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (inView && article) {
      trackInfoCardImpression({ article });
    }
  }, [inView, article, trackInfoCardImpression]);

  return (
    <Link
      href={externalLink ? externalLink : `/${slug}/a/${article.article_id}`}
      className={styles.link}
      rel={"canonical"}
      ref={ref}
      prefetch={true}
      onClick={() => {
        trackInfoCardClick({ article });
        return openModal(
          searchParams.size > 0 ? `${pathname}?${searchParams}` : pathname,
        );
      }}
    >
      {children}
    </Link>
  );
};

export { InfoCardLink };
